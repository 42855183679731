<template>
  <v-container>
    <v-row v-if="pageIsLoading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>

    <v-alert
      v-model="task_status_is_submitted"
      transition="fade-transition"
      type="success"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="task_status_is_submitted = false"
        >mdi-close</v-icon
      >
      <div class="mr-10 text-subtitle-1">Successfully Updated</div>
    </v-alert>

    <v-alert
      v-model="error"
      transition="fade-transition"
      type="error"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="error = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">
        {{ errorMessage }}
      </div>
    </v-alert>

    <v-row v-if="!pageIsLoading" justify="center">
      <v-col xs="12" xl="8">
        <v-row>
          <v-col>
            <v-breadcrumbs
              :items="[
                {
                  text: 'Deal',
                  disabled: false,
                  href: `/dashboard/admin/deal/read/${stg_obj_tsk.stg_obj.stg_itm.deal.id}`,
                },

                {
                  text: 'Objective',
                  disabled: false,
                  href: `/dashboard/admin/kanban/Deal/stage-item/${stg_obj_tsk.stg_obj.stg_itm.id}/objective/read/${stg_obj_tsk.stg_obj.id}`,
                },

                {
                  text: `Task - ${$options.filters.capitalize_first_letter(
                    stg_obj_tsk.subject
                  )}`,
                  disabled: true,
                },
              ]"
              divider="/"
              large
            >
            </v-breadcrumbs>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel class="bordered">
                <v-expansion-panel-header class="d-flex align-center">
                  <div>
                    <v-icon>mdi-clipboard-text-outline</v-icon> Task Information
                  </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-alert
                    class="mt-5"
                    v-show="hasChanges"
                    color="orange darken-3"
                    dense
                    transition="fade-transition"
                  >
                    <div>
                      <v-icon>mdi-information-outline</v-icon>
                      <span class="ml-2">
                        The following changes will only be
                        applied after clicking the update button.
                      </span>
                    </div>
                  </v-alert>

                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="white--text text-center" :width="200">
                          FIELDS
                        </th>
                        <th class="white--text text-center">DETAILS</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>TASK NUMBER</td>
                        <td class="pl-7">
                          <v-label> {{ stg_obj_tsk.id }} </v-label>
                        </td>
                      </tr>

                      <tr>
                        <td>SUBJECT</td>
                        <td>
                          <v-text-field
                            v-model="stg_obj_tsk.subject"
                            flat
                            dense
                            hide-details
                            solo
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>DESCRIPTION</td>
                        <td>
                          <v-textarea
                            v-model="stg_obj_tsk.description"
                            rows="1"
                            auto-grow
                            flat
                            dense
                            hide-details
                            solo
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>DAYS PASSED</td>
                        <td class="pl-7">
                          <v-label> {{ stg_obj_tsk.count }} </v-label>
                        </td>
                      </tr>

                      <tr>
                        <td>DUE DATE</td>
                        <td>
                          <v-text-field
                            v-model="stg_obj_tsk.new_day_limit"
                            flat
                            dense
                            hide-details
                            solo
                            type="date"
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>STATUS</td>
                        <td>
                          <v-select
                            v-model="stg_obj_tsk.status"
                            :items="status_options"
                            flat
                            dense
                            hide-details
                            solo
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>ASSIGNEES</td>
                        <td class="d-flex align-center">
                          <v-select
                            v-model="stg_obj_tsk.selected_assignees"
                            :items="assignees"
                            item-value="id"
                            item-text="username"
                            flat
                            dense
                            hide-details
                            multiple
                            solo
                          />
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>

                  <div class="mt-5">
                    <v-btn
                      class="mr-2"
                      :disabled="!hasChanges || task_status_is_updating"
                      @click="handle_update_task"
                      color="teal"
                    >
                      <v-progress-circular
                        v-show="task_status_is_updating"
                        class="mr-2"
                        color="primary"
                        indeterminate
                      />
                      Update
                    </v-btn>

                    <v-btn @click="$emit('close')"> cancel </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel class="bordered">
                <v-expansion-panel-header class="d-flex align-center">
                  <div>
                    <v-icon>mdi-file-document-edit-outline</v-icon>
                    Helpers
                  </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-data-table
                    :headers="helper_headers"
                    :items="stg_tsk_hlps"
                    :hide-default-footer="true"
                    item-key="id"
                    class="mt-5"
                    @click:row="handleClickHelper"
                  >
                    <template v-slot:item.createdAt="{ item }">
                      {{ formatDate(item.createdAt) }}
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-row class="mt-5">
              <v-col>
                <!-- note list -->
                <v-card
                  style="background: transparent"
                  ref="task_note_list"
                  flat
                >
                  <p class="text-h6 font-weight-medium">
                    Notes
                    <v-icon> mdi-note-edit-outline </v-icon>
                  </p>
                  <p class="mb-0 grey--text font-weight-medium">
                    Click on the text field to input your notes, you can also
                    add screenshots or image files by copy/paste or drag and
                    drop into the text field below.
                  </p>

                  <v-card-text>
                    <p
                      v-if="stg_obj_tsk.notes.length === 0"
                      class="text-center grey--text text-subtitle-1"
                    >
                      No notes available. Make a note below.
                    </p>

                    <div
                      v-for="(note, index) in stg_obj_tsk.notes"
                      :key="note.id"
                      class="my-2"
                    >
                      <v-card class="rounded-lg">
                        <v-card-text>
                          <p class="d-flex align-center mb-0">
                            <span>
                              <v-icon class="text-body-1 mr-1">
                                mdi-account-outline
                              </v-icon>
                              {{ note.user ? note.user.first_name : "" }}
                              {{ note.user ? note.user.last_name : "" }}
                            </span>

                            <span class="ml-auto">
                              <v-icon class="text-body-1 mr-1">
                                mdi-calendar-outline
                              </v-icon>
                              {{ note.createdAt | formatDate }}
                            </span>

                            <span class="ml-5">
                              <v-btn
                                title="Click to edit note"
                                @click="handle_edit_note(note, index)"
                                icon
                                small
                              >
                                <v-icon color="teal" class="text-body-1">
                                  mdi-pencil-outline
                                </v-icon>
                              </v-btn>

                              <v-btn
                                title="Click to delete"
                                @click="handle_delete_note(note.id)"
                                icon
                                small
                              >
                                <v-icon color="red"> mdi-close </v-icon>
                              </v-btn>
                            </span>
                          </p>
                        </v-card-text>

                        <v-divider />

                        <v-card-text>
                          <div v-if="note.note" style="white-space: pre">
                            {{ note.note }}
                          </div>

                          <div
                            v-if="note.content"
                            class="note-contents"
                            v-html="note.content"
                          ></div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-card-text>
                </v-card>

                <!-- note editor -->
                <v-card
                  style="background: transparent"
                  ref="task_note_editor"
                  flat
                >
                  <v-card-text class="pa-1">
                    <div>
                      <RichTextEditor
                        ref="taskTipTapEditor"
                        type="stg_obj_tsk"
                        :id="stg_obj_tsk.id"
                        :user_id="$store.getters['Auth/getAuthUser'].id"
                        @handleSaveNote="handle_save_note"
                      />
                    </div>
                  </v-card-text>
                </v-card>

                <v-dialog v-model="note_dialog" fullscreen>
                  <v-toolbar class="sticky-toolbar orange darken-3" flat>
                    <v-toolbar-title>
                      <v-icon>mdi-image</v-icon> Image Preview
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="note_dialog = false" title="Close">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>

                  <v-card>
                    <v-card-text>
                      <v-img :src="selectedImage" aspect-ratio="1" />
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import filt from "@/plugins/filters";
import RichTextEditor from "@/components/note/TipTapEditor.vue";

export default {
  props: [
    "kanban_type",
    "stage_item_id",
    "stage_objective_id",
    "stage_objective_task_id",
    "view_type",
  ],

  components: {
    RichTextEditor,
  },

  data: () => ({
    panel: [0, 1],
    pageIsLoading: true,
    error: false,
    errorMessage: "",

    task_status_is_updating: false,
    task_status_is_submitted: false,

    note_dialog: false,
    selectedImage: "",
    temp_task_note_index: null,

    assignees: [],

    stg_obj_tsk: {},

    stg_tsk_hlps: [],

    status_options: ["To Do", "On Progress", "Done", "Archived"],

    helper_headers: [
      { text: "#", value: "id" },
      { text: "Title", value: "title" },
      { text: "Description", value: "description" },
      { text: "Created At", value: "createdAt" },
    ],
  }),

  methods: {
    scrollToElement(element) {
      window.scrollTo({
        top: element.offsetTop - 5, // adjust the scroll position by 100px
        behavior: "smooth",
      });
    },

    getDays(created_date) {
      // strapi date format to mm/dd/yyyy
      const dateFormatted = this.formatDate(created_date);

      // specify the start date in mm/dd/yyyy format
      const startDate = new Date(dateFormatted);

      // get the current system date
      const endDate = new Date();

      // calculate the time difference between the two dates
      const timeDiff = endDate.getTime() - startDate.getTime();

      // convert the time difference to days
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      return daysDiff;
    },

    formatDate(date_value) {
      // create a new Date object from the string
      const dateObj = new Date(date_value);

      // extract the date components from the date object
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1; // add 1 since month is zero-based
      const day = dateObj.getDate();

      // format the date components as mm/dd/yyyy
      const formattedDate = `${month}/${day}/${year}`;

      return formattedDate;
    },

    convertDayLimit() {
      const date = new Date(this.stg_obj_tsk.createdAt);
      date.setDate(date.getDate() + this.stg_obj_tsk.day_limit);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const result = `${year}-${month}-${day}`;
      this.stg_obj_tsk.new_day_limit = result;
    },

    handleClickHelper(value) {
      this.$router.push({
        path: `/dashboard/admin/kanban/${this.kanban_type}/stage-item/${this.stage_item_id}/objective/${this.stage_objective_id}/task/${this.stage_objective_task_id}/helper/${value.id}/read`,
      });
    },

    async handle_update_task() {
      try {
        this.task_status_is_updating = true;

        console.log("sending: ", this.stg_obj_tsk);

        // count from created date to new_day_limit
        // strapi date format to mm/dd/yyyy
        const dateFormatted = this.formatDate(this.stg_obj_tsk.createdAt);

        // specify the start date in mm/dd/yyyy format
        const startDate = new Date(dateFormatted);

        // get the day limit
        const endDate = new Date(this.stg_obj_tsk.new_day_limit);

        // calculate the time difference between the two dates
        const timeDiff = endDate.getTime() - startDate.getTime();

        // convert the time difference to days
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        // update the day limit
        this.stg_obj_tsk.day_limit = daysDiff;

        const update_res = await API().patch(
          "api/kanban/update_stage_objective_task",
          {
            user_id: this.$store.getters["Auth/getAuthUser"].id,
            stg_obj_tsk: this.stg_obj_tsk,
          }
        );

        console.log(update_res);

        if (update_res.status === 201) {
          console.log(update_res.data);

          this.task_status_is_updating = false;
          this.task_status_is_submitted = true;

          setTimeout(() => {
            this.task_status_is_submitted = false;
            this.view_type === "pop_up"
              ? this.$emit("close")
              : this.$router.go(-1);
          }, 2000);
        } else {
          this.error = true;
          this.errorMessage = "Failed to update task. Please try again.";
          this.task_status_is_updating = false;
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
        this.task_status_is_updating = false;
      }
    },

    async readStageObjectiveTask() {
      try {
        this.pageIsLoading = true;

        // get stage objective task data
        const read_stg_obj_tsk = await API().get(
          `api/kanban/read_stage_objective_task/?stg_obj_tsk_id=${Number(
            this.stage_objective_task_id
          )}`
        );

        this.stg_obj_tsk = { ...read_stg_obj_tsk.data, new_day_limit: "" };

        // console.log("stage objective task: ", this.stg_obj_tsk);

        this.stg_obj_tsk.count = this.getDays(this.stg_obj_tsk.createdAt);

        this.convertDayLimit();

        const read_internal_users = await API().get(
          "api/kanban/get_internal_users"
        );

        this.assignees = read_internal_users.data;

        // get stage task helpers data

        const read_stg_tsk_hlps = await API().get(
          `api/kanban/read_stage_helpers/?task_id=${Number(
            this.stage_objective_task_id
          )}`
        );

        // console.log(read_stg_tsk_hlps.data);

        this.stg_tsk_hlps = read_stg_tsk_hlps.data;

        this.initialState = { ...this.stg_obj_tsk }; // Store initial state

        this.pageIsLoading = false;

        this.$nextTick(() => {
          if (this.$refs.task_note_list) {
            this.$refs.task_note_list.$el.addEventListener(
              "click",
              this.onNoteImageClick
            );
          }
        });
      } catch (error) {
        console.log(error);
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 2000);
      }
    },

    handle_save_note(note, it_is_edit) {
      if (it_is_edit) {
        this.stg_obj_tsk.notes[this.temp_task_note_index].content =
          note.content;
        this.temp_task_note_index = null;

        const task_note_list = this.$refs.task_note_list.$el;
        this.scrollToElement(task_note_list);
      } else {
        this.stg_obj_tsk.notes.push(note);
      }
    },

    async handle_delete_note(id) {
      try {
        if (window.confirm("Are you sure you want to delete this note?")) {
          this.loadingNotes = true;
          const response = await API().post(
            `/api/internal-admin/note/delete?note_id=${id}`
          );

          console.log("Response: ", response.message);

          this.stg_obj_tsk.notes = this.stg_obj_tsk.notes.filter(
            (note) => note.id !== id
          );
        } else {
          console.log("Delete cancelled");
          return;
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = `Error ${error.response.status}: ${error.response.data.message}`;
      }
    },

    handle_edit_note(note, index) {
      this.temp_task_note_index = index;

      // set the editor content
      this.$refs.taskTipTapEditor.setContentToEdit(note);

      const task_note_editor = this.$refs.task_note_editor.$el;
      this.scrollToElement(task_note_editor);
    },

    onNoteImageClick(event) {
      if (event.target.tagName === "IMG") {
        this.selectedImage = event.target.src;
        this.note_dialog = true;
      }
    },
  },

  computed: {
    hasChanges() {
      const result =
        JSON.stringify(this.initialState) !== JSON.stringify(this.stg_obj_tsk);
      return result;
    },

    stg_obj_tsk_count() {
      // strapi date format to mm/dd/yyyy
      const dateFormatted = this.formatDate(this.stg_obj_tsk.createdAt);

      // specify the start date in mm/dd/yyyy format
      const startDate = new Date(dateFormatted);

      // get the current system date
      const endDate = new Date();

      // calculate the time difference between the two dates
      const timeDiff = endDate.getTime() - startDate.getTime();

      // convert the time difference to days
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      return daysDiff;
    },
  },

  mounted() {
    this.readStageObjectiveTask();
  },

  filters: {
    ...filt,
  },
};
</script>

<style lang="scss">
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
  padding: 5px;
}
</style>
